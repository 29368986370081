<template>
  <v-row justify="center">
    <v-card width="600">
      <v-card-title>Login</v-card-title>
      <v-card-text>
        <v-text-field v-model="email" label="Email" />
        <v-text-field v-model="password" label="Password" type="password" />
      </v-card-text>
      <v-card-actions>
        <v-btn @click="login" color="primary">Submit</v-btn>
      </v-card-actions>
    </v-card>
  </v-row>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Login",

  data() {
    return {
      email: "",
      password: ""
    };
  },

  computed: {
    ...mapState({
      user: state => state.auth.currentUser
    })
  },

  methods: {
    login() {
      this.$store
        .dispatch("login", {
          email: this.email,
          password: this.password
        })
        .then(() => {
          window.location = "/";
        });
    }
  }
};
</script>
